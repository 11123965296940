import request from "@/util/request.js";
//获取新闻种类
export function userLogin(params) {
  return request({
    url: `/api/admin/login`,
    method: "post",
    params,
  });
}
// ClassifiedNews
export function ClassifiedNews(id) {
  return request({
    url: `/api/admin/posts?page=0&size=6&categoryld=${id}`,
    method: "get",
  });
}
// 公司列表
export function getCompanyList(params) {
  return request({
    url: `/cms/api/app/company/list`,
    method: "get",
    params,
  });
}
export function getCompanyDetail(id) {
  return request({
    url: `/cms/api/app/company/` + id,
    method: "get",
  });
}
// 项目列表
export function getPojectList(params) {
  return request({
    url: `/cms/api/app/project/list`,
    method: "get",
    params,
  });
}
// 项目列表
export function getPojectDetail(id) {
  return request({
    url: `/cms/api/app/project/detail/` + id,
    method: "get",
  });
}

// 项目列表
export function getLandDetail(id) {
  return request({
    url: `/cms/api/app/project/landDetail/` + id,
    method: "get",
  });
}

export function getTrends(params) {
  return request({
    url: `/cms/api/app/project/trends/list`,
    method: "get",
    params,
  });
}

export function getProgress(params) {
  return request({
    url: `/cms/api/app/project/progress/list`,
    method: "get",
    params,
  });
}

export function getNewsList(params) {
  return request({
    url: `/cms/api/app/news/list`,
    method: "get",
    params,
  });
}

export function getAccessToken() {
  return request({
      url: `/cms/api/wechat/getAccessToken`,
      method: 'get',
  })
}

export function getSignature(params) {
  return request({
      url: `/cms/api/wechat/getSignature`,
      method: 'get',
      params
  })
}


export default {
  ClassifiedNews,
  userLogin,
};
