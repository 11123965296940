<template>
  <div id="app">
    <div class="header">
      <div class="header_bg">
        <div @click="toPage('/', {})" class="header-img">
        </div>
        <div class="header-center">
          <div v-for="item in pageList" :key="item.id" @mouseleave="item.id == '2' ? mouseLeaveMenu() : null"
            @mouseover="item.id == '2' ? mouseOverMenu() : null" :style="{
              background:
                nav_id == item.id ? '#354D33 !important' : 'transparent',
            }" style="height: 100px;">
            <div :class="{ 'header-text-border': nav_id == item.id }" class="header-text" @click="nav_click(item.id)">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="navBox">
      <div class="navigation" @mouseleave="mouseLeaveMenu()" @mouseover="mouseOverMenu()">
        <div class="navigation_list" v-for="(item, index) in treeData" :key="item.companyId">
          <!-- width="100%" -->
          <div style="position: relative" class="moveTop" @click="toPage('/base', item, index, 0)">
            <img :src="item.companyImage" />
            <div :key="index" class="home_four_module_bg"></div>
            <div class="fontSize" @click="toPage('/base', item, index)">
              {{ item.companyName }}
            </div>
          </div>
          <div class="navigation_list_title moveTop" @click="toPage('/base', item, index, 0)">
            <div>{{ item.companyName }}</div>
            <div><i class="el-icon-arrow-right"></i></div>
          </div>
          <div class="navigation_name">
            <div class="navigation_name_item moveTop" v-for="(item1, index) in item.treeChildData"
              :key="item1.companyId" @click="toPage('/baseCompanyDetail', item1, index, 1, item)"><span
                style="font-size: 12px;">●</span>&nbsp;&nbsp;{{
                  item1.companyName }}</div>
          </div>
        </div>
      </div>
    </div>
    <router-view />
    <div class="foot">
      <div class="foot_top_box">
        <div class="foot_top_box_content">
          <div class="foot_center_box">
            <ul v-for="item in list" :key="item.id">
              <!-- <div class="foot_center_box_head">
                {{ item.head }}
              </div> -->
              <li class="link" v-if="item.head == '网站'" @click="toPage('/', {})">
                {{ item.content.content1 }}
              </li>
              <li v-else>
                {{ item.content.content1 }}
              </li>
              <li class="link" v-if="item.head == '网站'" @click="toPage('/introduction', {})">
                {{ item.content.content2 }}
              </li>
              <li v-else>
                {{ item.content.content2 }}
              </li>
              <li class="link" v-if="item.head == '网站'" @click="toPage('/newsList', {})">
                {{ item.content.content3 }}
              </li>
              <li v-else>
                {{ item.content.content3 }}
              </li>
              <li class="link" v-if="item.head == '网站'" @click="toPage('/business', {})">
                {{ item.content.content4 }}
              </li>
              <li v-else>{{ item.content.content4 }}</li>
            </ul>
          </div>
          <div class="foot_center_left_box">
            <div style="text-align: center" class="foot_logo">
            </div>
          </div>
        </div>
      </div>
      <div class="foot_bottom_box">
        <div>
          <a target="_blank" href="https://beian.miit.gov.cn" style="
              display: inline-block;
              text-decoration: none;
              height: 20px;
              line-height: 20px;
            "><img src="../static/image/beian.png" style="float: left" />
            <p style="
                float: left;
                height: 20px;
                line-height: 20px;
                margin: 0px 0px 0px 5px;
                color: rgba(255, 255, 255, 0.56);
              ">
              浙ICP备2020034128号-2 &nbsp;&nbsp;&nbsp;&nbsp;
            </p>
          </a><a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33060302001254"
            style="
              display: inline-block;
              text-decoration: none;
              height: 20px;
              line-height: 20px;
            ">
            <p style="
                float: left;
                height: 20px;
                line-height: 20px;
                margin: 0px 0px 0px 5px;
                color: rgba(255, 255, 255, 0.56);
              ">
              浙公网安备33060302001254号
            </p>
          </a>
          <p style="
              color: rgba(255, 255, 255, 0.56);
              font-size: 12px;
              text-align: center;
              display: inline-block;
              margin: 0px 0px 0px 16px;
              vertical-align: super;
            ">
            中峪道地药材 版权所有
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCompanyList } from "@/api/index.js";
export default {
  name: "App",
  data() {
    return {
      nav_id: "1",
      pageList: [
        {
          id: "1",
          text: "网站首页",
        },
        {
          id: "2",
          text: "药材基地",
        },
        {
          id: "3",
          text: "行业介绍",
        },
        {
          id: "4",
          text: "新闻资讯",
        },
        {
          id: "5",
          text: "药材合集",
        },
      ],
      list: [
        {
          head: "网站",
          content: {
            content1: "网站首页",
            content2: "行业介绍",
            content3: "新闻资讯",
            content4: "药材合集",
          },
        },
        {
          head: "联系方式",
          content: {
            content1: "联系方式",
            content2: "客服电话：400-185-3168",
            content3: "公司名称：浙江中峪农业发展有限责任公司",
            content4: "地址：浙江绍兴柯桥区IFC国际金融中心裙楼2F",
          },
        },
      ],
      showNavigation: true,
      treeData: [],
    };
  },
  mounted() {
    this.getList();
    if (window.screen.width < 1280) {
      document.getElementsByClassName("navBox")[0].style.transform =
        "scale(" + window.screen.width / 1280 + ")";
    } else {
      document.getElementsByClassName("navBox")[0].style.transform = "scale(1)";
    }
  },
  methods: {
    getList() {
      //记得修改回来
      getCompanyList({ current: 1, size: 100, pid: 3 }).then((res) => {
        this.treeData = [];
        res.data.list.forEach((val) => {
          if (val.companyCategory == 0) {
            getCompanyList({ current: 1, size: 100, pid: val.companyId }).then(
              (res1) => {
                this.$set(val, "treeChildData", res1.data.list);
                this.treeData.push(val);
              }
            );
          }
        });
      });
    },
    nav_click(x) {
      if (x == 2) {
        if (
          document.getElementsByClassName("navigation")[0].style.height ==
          "auto"
        ) {
          document.getElementsByClassName("navigation")[0].style =
            "height: 0;z-index: -1";
          document.getElementsByClassName("navBox")[0].style.height = 0;
          document.getElementsByClassName("navBox")[0].style["z-index"] = -1;
          document.body.append(document.getElementsByClassName("navBox")[0]);
        } else {
          document.getElementsByClassName("navigation")[0].style =
            "height: auto;z-index: 2";
          document.getElementsByClassName("navBox")[0].style.height = "auto";
          document.getElementsByClassName("navBox")[0].style["z-index"] = 2;
          // document.body.remove(document.getElementsByClassName('navBox')[0])
        }
      }
      switch (x) {
        case "1":
          this.nav_id = x;
          this.$router.push("/");
          break;
        case "2":
          document.getElementsByClassName("navigation")[0].style =
            "height: auto;z-index: 2";
          document.getElementsByClassName("navBox")[0].style.height = "auto";
          document.getElementsByClassName("navBox")[0].style["z-index"] = 2;
          document.body.append(document.getElementsByClassName("navBox")[0]);
          break;
        case "3":
          this.nav_id = x;
          this.$router.push("/introduction");
          break;
        case "4":
          this.nav_id = x;
          this.$router.push("/newsList");
          break;
        case "5":
          this.nav_id = x;
          this.$router.push("/business");
          break;
      }
    },
    toPage(path, item, index, type, pitem) {
      if (item.companyId) {
        document.getElementsByClassName("navigation")[0].style =
          "height: 0;z-index: 2";
        this.showNavigation = false;
        if (type == 0) {
          let len = 0;
          this.treeData.forEach((val, ind) => {
            if (ind < index) {
              len += val.treeChildData.length;
            }
          });
          this.$router.push({
            path,
            query: { companyId: item.companyId, index: len },
          });
        } else {
          if (type) {
            this.$router.push({
              path,
              query: {
                companyId: item.companyId,
                index,
                pCompanyName: pitem.companyName,
                pCompanyId: pitem.companyId,
              },
            });
          } else {
            this.$router.push({
              path,
              query: { companyId: item.companyId, index },
            });
          }
        }
        this.nav_id = 2;
        setTimeout(() => {
          this.showNavigation = true;
        }, 500);
      } else {
        if (this.$route.path == path) {
          location.reload();
        } else {
          this.$router.push(path);
        }
      }
    },
    mouseLeaveMenu() {
      document.getElementsByClassName('navigation')[0].style = 'height: 0;z-index: 2'
      document.getElementsByClassName('navBox')[0].style.height = '0'
      document.getElementsByClassName('navBox')[0].style['z-index'] = 2
    },
    mouseOverMenu() {
      if (document.getElementsByClassName('navBox')[0].style.height != 'auto') {
        document.getElementsByClassName('navigation')[0].style = 'height: auto;z-index: 2'
        document.getElementsByClassName('navBox')[0].style.height = 'auto'
        document.getElementsByClassName('navBox')[0].style['z-index'] = 2
        document.body.append(document.getElementsByClassName('navBox')[0])
      }
    },
  },
  watch: {
    $route(to) {
      let path = to.path;
      if (path[path.length - 1] == "/") {
        path = path.substring(0, path.length - 1);
      }
      switch (path) {
        case "":
          this.nav_id = 1;
          break;
        case "/":
          this.nav_id = 1;
          break;
        case "/base":
          if (to.query.companyId != 49) {
            this.nav_id = 2;
          } else {
            this.nav_id = -1;
          }
          break;
        case "/baseDetail":
          this.nav_id = 2;
          break;
        case "/baseCompanyDetail":
          this.nav_id = 2;
          break;
        case "/projectDetail":
          this.nav_id = 2;
          break;
        case "/landDetail":
          this.nav_id = 2;
          break;
        case "/introduction":
          this.nav_id = 3;
          break;
        case "/newsList":
          this.nav_id = 4;
          break;
        case "/business":
          this.nav_id = 5;
          break;
        case "/businessDetail":
          this.nav_id = 5;
          break;
        case "/newsListDetail":
          this.nav_id = 4;
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 100px;
  background-image: url("../static/image/head_bg.png");
  background-size: contain;
  position: sticky;
  top: 0;
  z-index: 10;

  .header_bg {
    display: flex;
    align-items: center;
    width: 1280px;
    margin: 0 auto;
    justify-content: space-between;
  }

  .header-img {
    background-image: url("../static/image/logo.png");
    background-repeat: no-repeat;
    background-size: 100%;
    cursor: pointer;
    height: 88px;
    width: 250px;
  }

  .header-center {
    display: flex;
    height: 100px;
    line-height: 100px;
    margin-left: 110px;
    white-space: nowrap;
    /* 防止文本换行 */
    overflow: hidden;
    /* 控制文本溢出时隐藏超出部分 */
    text-overflow: ellipsis;

    /* 当文本溢出时显示省略号 */
    div {
      max-width: 100px;
    }

    .header-text {
      // height: 62%;
      height: 100px;
      margin: 0 15px;
      font-size: 14px;
      cursor: pointer;
      font-weight: 400;
      color: #cbcbcb;
    }

    .header-text-border {
      font-size: 16px;
      color: #ffffff;
      border-bottom: 1px solid #ffffff;
    }
  }

  .header-center {
    &>div:hover {
      background-color: rgba(53, 77, 51, 0.3) !important;
      height: 100px;
    }
  }
}

@media (max-width: 767px) {
  .navBox {
    transform: scale(1);
    top: calc(100vw / 1280 * 100) !important;
    transform-origin: 0 0;
  }
}

.navBox {
  // position: absolute;
  top: 100px;
  left: 0;
  z-index: 2;
  width: 100%;
}

.navigation {
  background: url("../static/image/head_bg1.png") no-repeat;
  background-size: contain;
  background-position: 0 bottom;
  position: absolute;
  left: 0;
  width: 1280px;
  padding: 0px calc(50% - 640px) 0 calc(50% - 640px);
  height: 0;
  background-color: #f5f5f7;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  z-index: 2;
  justify-content: flex-start;
  transition: height 0.8s ease-out;
  box-shadow: 0px 2px 45px 0px rgba(0, 0, 0, 0.5);
  overflow-y: scroll;

  .navigation_list {
    width: 360px;
    margin-left: 50px;
    padding: 60px 0;

    .fontSize {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 20px;
      font-weight: 900;
      white-space: nowrap;
      cursor: pointer;
    }

    .home_four_module_bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 360px;
      height: 200px;
      background: linear-gradient(180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.71) 100%);
    }

    img {
      cursor: pointer;
      width: 360px;
      height: 200px;
      object-fit: cover;
    }

    .navigation_list_title {
      margin-top: 20px;
      width: 320px;
      height: 56px;
      background: rgba(203, 203, 203, 0.1);
      line-height: 56px;
      padding: 0 20px;
      font-size: 18px;
      font-weight: 400;
      color: #354a32;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }

    .navigation_name {
      margin-top: 8px;
      display: flex;
      flex-wrap: wrap;

      .navigation_name_item {
        width: 50%;
        font-size: 14px;
        font-weight: 400;
        color: #595959;
        line-height: 30px;
        margin-top: 10px;
        text-align: left;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.foot {
  width: 100%;
  background-color: #1d1b19;

  .foot_top_box {
    width: 1240px;
    padding: 0 20px;
    min-width: 720px;
    margin: 0 auto;
    padding-bottom: 22px;

    .foot_top_box_content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .foot_center_box {
        height: 168px;
        display: flex;
        margin-top: 90px;

        .foot_center_left_box {
          margin-top: 90px;
          width: 220px;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            color: rgba(255, 255, 255, 0.56);
            cursor: pointer;
            font-size: 12px;
            line-height: 32px;
            margin: 0 50px 0 0;
          }

          .link:hover {
            color: #ffffff;
          }
        }
      }

      .foot_center_box_head {
        height: 26px;
        color: #ffffff;
        cursor: pointer;
        font-size: 14px;
        margin-bottom: 8px;
      }
    }
  }

  .foot_logo {
            background-image: url('../static/image/foot_logo.png');
            background-repeat: no-repeat;
            background-size: 100%;
            width: 100px;
            height: 122px;
          }
  .foot_bottom_box {
    background: #11100f;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    line-height: 80px;
  }
}
</style>
