import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const router = new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            name: '',
            component: () => import('@/view/index.vue'),
        },
        {
            path: '/index',
            name: 'index',
            component: () => import('@/view/index1.vue'),
        },
        {
            path: '/base',
            name: 'base',
            component: () => import('@/view/base.vue'),
        },
        {
            path: '/baseOther',
            name: 'baseOther',
            component: () => import('@/view/baseOther.vue'),
        },
        {
            path: '/baseDetail',
            name: 'baseDetail',
            component: () => import('@/view/baseDetail.vue'),
        },
        {
            path: '/baseCompanyDetail',
            name: 'baseCompanyDetail',
            component: () => import('@/view/baseCompanyDetail.vue'),
        },
        {
            path: '/projectDetail',
            name: 'projectDetail',
            component: () => import('@/view/projectDetail.vue'),
        },
        {
            path: '/landDetail',
            name: 'landDetail',
            component: () => import('@/view/landDetail.vue'),
        },
        {
            path: '/introduction',
            name: 'introduction',
            component: () => import('@/view/introduction.vue'),
        },
        {
            path: '/business',
            name: 'business',
            component: () => import('@/view/business.vue'),
        },
        {
            path: '/businessDetail',
            name: 'businessDetail',
            component: () => import('@/view/businessDetail.vue'),
        },
        {
            path: '/newsList',
            name: 'newsList',
            component: () => import('@/view/newsList.vue'),
        },
        {
            path: '/newsListDetail',
            name: 'newsListDetail',
            component: () => import('@/view/newsListDetail.vue'),
        },
        {
            path: '/companyNewsListDetail',
            name: 'companyNewsListDetail',
            component: () => import('@/view/companyNewsListDetail.vue'),
        },
        {
            path: '/projectSchedule',
            name: 'projectSchedule',
            component: () => import('@/view/projectSchedule.vue'),
        },
        {
            path: '/projectScheduleList',
            name: 'projectScheduleList',
            component: () => import('@/view/projectScheduleList.vue'),
        },
        {
            path: '/PublicityDocumentList',
            name: 'PublicityDocumentList',
            component: () => import('@/view/PublicityDocumentList.vue'),
        },
        {
            path: '/PublicityDocument',
            name: 'PublicityDocument',
            component: () => import('@/view/PublicityDocument.vue'),
        },
        {
            path: '/m',
            name: 'm',
            component: () => import('@/view/mIndex.vue'),
        },
    ]
})
export default router
