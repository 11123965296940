import { render, staticRenderFns } from "./app.vue?vue&type=template&id=2c283d98&scoped=true&"
import script from "./app.vue?vue&type=script&lang=js&"
export * from "./app.vue?vue&type=script&lang=js&"
import style0 from "./app.vue?vue&type=style&index=0&id=2c283d98&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c283d98",
  null
  
)

export default component.exports